import { makeAutoObservable } from "mobx";
import { createTask, discoverArticle, getTaskDetail, selectArticle, updateTaskDetail, addStrategy, aiAskHelp, collectAiHelp, aiStrategyHelp, submitSelfReflection, submitReview, countReadingTime, submitSelfAssessment, generateExercises, submitExercises, submitSurvey } from '../utils/request'
import { message } from 'antd';
import { create, persist } from 'mobx-persist'
import userStore from "./userStore";
import { countWords } from "../utils/utils";

// Todo 總數據源
class TaskStore {

  task =  {
    aiArticles: [],
    aiArticleLeft: 3,
    aiHelps: [],
    usedStrategies: [],
    reviewStrategies: [],
    summary: '',
    step: 0,
    exercises: []
  }

  timer = undefined

  selfReflection = {
    difficulty: 0,
    performance: 0,
    isInteresting: undefined,
    isEncounter: undefined,
    encounterContent: ''
  }

  showSurvey = false
  survey = {
    interesting: 0,
    enjoy: 0
  }

  genreType = undefined
  readingLevel = undefined
  articleLength = undefined
  interest = undefined

  loading = false
  submitLoading = false
  generateLoading = false

  summary = ''

  showAssessment = false
  selfAssessment = {
    
    entertainment: 0,
    interesting: 0,
    like: 0,

    concentrate: 0,
    understand: 0,
    focus: 0,

    suppose: 0,
    perform: 0,
    hard: 0
  }

  exerciseAnswers = []
  showExercises = false
  showAnswer = false

  constructor() {
    makeAutoObservable(this);
  }

  startCount() {
    this.timer = setInterval(() => {
      this.countReadingTime()
    }, 10000)
  }

  finishCount() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  async countReadingTime() {
    const result = await countReadingTime(this.task.id)
    // 弹窗显示self-assessment
    if (userStore.profile?.hasAssessment) {
      if (!result.data.selfAssessment && !this.showAssessment) {
        this.showAssessment = true
      }
    }
  }

  async submitAssessment() {
    this.submitLoading = true
    const result = await submitSelfAssessment({
      taskId: this.task.id,
      ...this.selfAssessment
    })
    // this.task.selfAssessment = result.data.selfAssessment
    this.submitLoading = false
    this.showAssessment = false
  }

  async getTaskDetail(id) {
    this.loading = true
    const result = await getTaskDetail(id)
    this.task = result.data
    if (this.task.step === 3) {
      this.summary = this.task.summary
    }
    if (result.data.survey) {
      this.survey = result.data.survey
    }
    if (result.data.exercises && result.data.exercises.length > 0 && result.data.exercises[0].answer !== null) {
      this.exerciseAnswers = result.data.exercises.map(item => item.answer)
    }
    this.task.aiArticles = this.dealWithAiArticles(result.data.aiArticles)
    this.task.aiHelps = this.dealWithAiHelps(result.data.aiHelps)
    if (!result.data.reviewStrategies || result.data.reviewStrategies.length ===0) {
      this.task.reviewStrategies = result.data.usedStrategies.map(item => {
          return {
            challenge: item.challenge,
            challengeId: item.challengeId,
            strategy: item.strategy,
            dimension: item.dimension,
            strategyId: item.id,
            technique: ''
          }
      })
    }

    this.loading = false
    return result
  }

  async updateTaskDetail(params) {
    const result = await updateTaskDetail({
      id: this.task.id,
      ...params
    })
    return result
  }

  async submitReview() {
    this.submitLoading = true

    const result = await submitReview({
      taskId: this.task.id,
      summary: this.summary,
      reviewStrategies: this.task.reviewStrategies
    })

    this.task.step = result.data.step
    this.task.isCompleted = result.data.isCompleted

    this.submitLoading = false
    message.success('Congratulations! You\'ve successfully completed a task. Please access the Editorial Board to engage in discussion.')
  
    return true
  }

  deleteReviewStrategy(index) {
    if (this.task.reviewStrategies.length === 1) {
      message.warning('You must have at least one review strategy.')
      return
    }
    this.task.reviewStrategies.splice(index, 1)
  }

  

  
  async createTask() {
    const result = await createTask()
    // this.task = result.data
    // this.task.aiArticles = this.dealWithAiArticles(result.data.aiArticles)
    return result
  }

   discoverArticle = async() => {
    if (this.loading) return 

    if(this.task.aiArticleLeft <= 0) {
      message.warning('You have no more chance to discover articles.')
      return
    }

    // 校验
    if (this.genreType === undefined) {
      message.warning('Please select the genre of article.')
      return
    } else if (this.readingLevel === undefined) {
      message.warning('Please select the reading level of article.')
      return
    } else if (this.articleLength === undefined) {
      message.warning('Please select the article length.')
      return
    } else if (userStore.profile && !!userStore.profile.hasInterest && countWords(this.interest) === 0) {
      message.warning('Please input the topic of your personal interests.')
      return
    }

    // 请求
    this.loading = true
    const result = await discoverArticle({
      taskId: this.task.id,
      genreType: this.genreType,
      readingLevel: this.readingLevel,
      articleLength: this.articleLength,
      interest: this.interest
    })

    this.task = result.data
    this.task.aiArticles = this.dealWithAiArticles(result.data.aiArticles)
    this.task.aiHelps = this.dealWithAiHelps(result.data.aiHelps)
    if (!result.data.reviewStrategies || result.data.reviewStrategies.length ===0) {
      this.task.reviewStrategies = result.data.usedStrategies.map(item => {
          return {
            challenge: item.challenge,
            challengeId: item.challengeId,
            strategy: item.strategy,
            dimension: item.dimension,
            strategyId: item.id,
            technique: ''
          }
      })
    }
    
    this.loading = false
  }

  dealWithAiArticles = (aiArticles) => {
    
    const returnHelps = [
      {
        role: 'assistant',
        content: 'Hello and welcome to Article Discovery! I\'m Reade, and I\'m here to help you find reading articles according to your selection.',
        hideSelect: true
      },
      ...aiArticles
    ]
    return returnHelps
  }

  dealWithAiHelps = (aiHelps) => {
    const returnHelps = [
      {
        role: 'assistant',
        content: 'As your ReadMate, I’m here to help you throughout the reading process.\n 😊If you would like me to explain and model a startegy, please click the button "Help" and select the strategy, I will demonstrate the techniques about how to use this strategy.\n \n 😊If you would like ask me any questions about the article, please input your questions below and click the button “Ask”, I will use relevant information in this article to give your answers. \n \n ⬅️On the left-hand side of the page，you can add new strategies to address any challenges you encounter while reading and use the heart icon to indicate how frequently you employ these strategies.',
        hideSelect: true
      },
      ...aiHelps
    ]
    return returnHelps
  }

  selectArticle = async (id) => {
    this.loading = true
    try {
      const result = await selectArticle({
        taskId: this.task.id,
        articleId: id,
      })
      this.loading = false
      return result

    } catch(e) {
      this.loading = false
    }
  }

  addStrategy = async (strategyId) => {
    const result = await addStrategy({
      taskId: this.task.id,
      strategyId
    })
    this.task.usedStrategies = result.data.usedStrategies
    return result
  }

  ask = async(content) => {
    const result = await aiAskHelp({
      taskId: this.task.id,
      question: content
    })
    this.task.aiHelps = this.dealWithAiHelps(result.data.aiHelps)
    this.task.aiAskLeft = result.data.aiAskLeft
    return result
  }

  strategyHelp = async (params) => {
    const result = await aiStrategyHelp({
      taskId: this.task.id,
      ...params
    })
    this.task.aiHelps = this.dealWithAiHelps(result.data.aiHelps)
    this.task.aiStrategyLeft = result.data.aiStrategyLeft
    return result
  }

  collectAiHelp = async (id, isCollect) => {
    const result = await collectAiHelp({
      taskId: this.task.id,
      aiHelpId: id,
      isCollect
    })
    this.task.aiHelps = this.dealWithAiHelps(result.data.aiHelps)
  }

  // submitSelfReflection = async () => {
    // this.submitLoading = true
    // const result = await submitSelfReflection({
    //   taskId: this.task.id,
    //   ...this.selfReflection
    // })
    // this.submitLoading = false
    // return result
  // }

  submitSurvey = async() => {
    this.submitLoading = true
    const result = await submitSurvey({
      taskId: this.task.id,
      ...this.survey
    })
    this.task.survey = result.data.survey
    this.submitLoading = false
    return result
  }

  submitExercises = async() => {
    this.submitLoading = true
    const result = await submitExercises({
      taskId: this.task.id,
      exerciseAnswers: this.exerciseAnswers
    })
    this.showAnswer = true
    this.submitLoading = false
    return true
  }

  generateExercises = async () => {
    if (this.task.exercises.length > 0) {
      if (this.task.exercises[0].answer !== null) {
        this.showAnswer = true
      }
      this.showExercises = true;
      return
    }

    this.generateLoading = true
    const result = await generateExercises(this.task.id)
    console.log(result)
    this.showExercises = true

    this.generateLoading = false
    this.task.exercises = result.data.exercises
    
    if (this.task.exercises[0].answer !== null) {
      this.showAnswer = true
      this.exerciseAnswers = this.task.exercises.map(item => item.answer)
    }

    
  }

}

const taskStore = new TaskStore()
export default taskStore;
